export function showError(formElement, message) {
  const errorDiv = document.createElement('div');
  errorDiv.className = 'error-message';
  errorDiv.textContent = message;
  formElement.insertBefore(errorDiv, formElement.firstChild);
}

export function clearErrors(formElement) {
  const errorDiv = formElement.querySelector('.error-message');
  if (errorDiv) {
    errorDiv.remove();
  }
  formElement.classList.remove('error');
}

export function getUTMParams() {
  const params = new URLSearchParams(window.location.search);
  const utmData = {};

  params.forEach((value, key) => {
    if (key.startsWith('utm_')) {
      utmData[key] = value;
    }
  });

  return utmData;
}

export function telRestriction(telField) {
  if (telField) {
    telField.addEventListener('input', function(event) {
      const validChars = /[^0-9()+\- ]/g;
      const input = event.target.value;

      event.target.value = input.replace(validChars, '');
    });
  }
}