import axios from "axios";
import validateForm from "./validation.js";
import { showError, clearErrors, getUTMParams, telRestriction } from './helper.js';
import MicroModal from 'micromodal';
import IMask from 'imask';

export default function initForms() {
  const forms = [
    {
      id: 'formQuestion',
      onSuccess: () => {
        MicroModal.show('modalThanks');
      },
    },
    {
      id: 'formConsultation',
      onSuccess: () => {
        MicroModal.close('modalConsultation');
        MicroModal.show('modalThanks');
      },
    },
  ];

  forms.forEach(({ id, onSuccess }) => {
    const formElement = document.getElementById(id);

    if (!formElement) {
      return;
    }

    const telField = formElement.querySelector('[type="tel"]');
    const submitButton = formElement.querySelector('[type="submit"]');

    const phoneMask = IMask(telField, {
      mask: '+{380} (00) 000 00 00',
      lazy: true,
      placeholderChar: ' ',
    });

    telRestriction(telField);

    formElement.addEventListener('submit', async (event) => {
      event.preventDefault();

      clearErrors(formElement);

      if (validateForm(formElement)) {
        submitButton.disabled = true;

        const formData = new FormData(formElement);

        const rawPhoneNumber = telField.value;
        const formattedPhoneNumber = rawPhoneNumber.replace(/\D/g, '');
        formData.set('phone', formattedPhoneNumber);

        const data = Object.fromEntries(formData);

        const utmParams = getUTMParams();
        Object.assign(data, utmParams);

        try {
          const response = await axios.post('/backend/', data, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });

          if (response.status === 200) {
            onSuccess();
            formElement.reset();
          } else {
            showError(formElement, 'Помилка під час відправки форми.');
            formElement.classList.add('error');
          }
        } catch (error) {
          showError(formElement, 'Помилка під час відправки форми.');
          formElement.classList.add('error');
        } finally {
          submitButton.disabled = false;
        }
      }
    });
  });
}