export default function header() {
  const header = document.getElementById('header');

  const checkHeaderScrolled = () => {
    if (window.scrollY > 0) {
      header.classList.add('scrolled');
    } else {
      header.classList.remove('scrolled');
    }
  };

  checkHeaderScrolled();

  window.addEventListener('scroll', checkHeaderScrolled);
}