import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';
import Swiper from 'swiper';
import { Pagination, Autoplay, EffectFade } from 'swiper/modules';

export default function slider() {
  new Swiper('.choice__slider .swiper', {
    loop: true,
    slidesPerView: 1,
    pagination: {
      el: '.choice__slider-pagination',
      clickable: true,
      renderBullet: function (index, className) {
        return `<span class="${className}"></span>`;
      },
    },
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    effect: 'fade',
    fadeEffect: {
      crossFade: true,
    },
    modules: [Pagination, Autoplay, EffectFade],
  });
}