export default function mobileMenu() {
  const hamburgerEl = document.getElementById('hamburger');
  const mobileMenuEl = document.getElementById('mobileMenu');
  const bodyEl = document.querySelector('body');
  const htmlEl = document.querySelector('html');

  if (hamburgerEl && mobileMenuEl) {
    hamburgerEl.addEventListener('click', (event) => {
      menuOpen();
    });

    mobileMenuEl.addEventListener('click', (event) => {
      const currentEl = event.target;

      if (currentEl.classList.contains('menu__link')) {
        menuOpen();
      }
    });

    mobileMenuEl.addEventListener('click', (event) => {
      const currentEl = event.target;

      if (currentEl.hasAttribute('data-micromodal-trigger')) {
        menuOpen();
      }
    });

    window.addEventListener('resize', () => {
      const width = window.innerWidth;

      if (width > 576 && htmlEl.classList.contains('menu-opened')) {
        menuOpen();
      }
    });
  }

  function menuOpen() {
    hamburgerEl.classList.toggle('is-active');
    mobileMenuEl.classList.toggle('open');
    bodyEl.classList.toggle('menu-opened');
    htmlEl.classList.toggle('menu-opened');
  }
}