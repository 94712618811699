import MicroModal from 'micromodal';

export default function modal() {
  MicroModal.init({
    onShow: () => {
      document.documentElement.classList.add('no-scroll');
      document.body.classList.add('no-scroll');
    },
    onClose: () => {
      document.documentElement.classList.remove('no-scroll');
      document.body.classList.remove('no-scroll');
    },
  });
}