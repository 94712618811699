import 'virtual:svg-icons-register'
import header from './js/header.js';
import mobileMenu from './js/mobileMenu.js';
import slider from "./js/slider.js";
import reviews from "./js/reviews.js";
import modal from "./js/modal.js";
import link from "./js/links.js";
import initForms from "./js/form/form.js";

header();
mobileMenu();
slider();
reviews();
modal();
link();
initForms();