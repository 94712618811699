import 'swiper/css';
import 'swiper/css/navigation';
import Swiper from 'swiper';
import {Navigation} from 'swiper/modules';

export default function reviews() {
  new Swiper('.reviews__list .swiper', {
    loop: true,
    slidesPerView: 1,
    navigation: {
      nextEl: '.reviews-button-next',
      prevEl: '.reviews-button-prev',
    },
    breakpoints: {
      576: {
        autoHeight: false,
      },
      0: {
        autoHeight: true,
      },
    },
    modules: [Navigation]
  });
}