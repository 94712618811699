export default function validateForm(formElement) {
  const formRows = formElement.querySelectorAll('.form-base__row');
  let isValid = true;

  formRows.forEach(row => {
    clearError(row);
  });

  const inputs = formElement.querySelectorAll('[data-validation]');
  inputs.forEach(input => {
    input.addEventListener('input', () => {
      const parentRow = input.closest('.form-base__row');
      if (parentRow.classList.contains('error')) {
        clearError(parentRow);
      }
    });

    const validationRules = JSON.parse(input.getAttribute('data-validation'));
    const inputValue = input.value.trim();
    const parentRow = input.closest('.form-base__row');

    for (const rule of validationRules) {
      if (rule === 'required' && inputValue === '') {
        isValid = false;
        addError(parentRow, 'Поле не повинно бути порожнім');
        break;
      }

      if (rule === 'phone') {
        const cleanedInput = inputValue.replace(/[^0-9]/g, '');

        if (!/^[0-9+\-()\s]+$/.test(inputValue)) {
          isValid = false;
          addError(parentRow, 'Поле має містити лише допустимі символи: цифри, +, (, ), та пробіли');
          break;
        }

        if (cleanedInput.length !== 12 || !cleanedInput.startsWith('380')) {
          isValid = false;
          addError(parentRow, 'Номер телефону має бути у форматі +380 (XX) XXX XX XX');
          break;
        }
      }
    }
  });

  return isValid;

  function addError(rowElement, message) {
    rowElement.classList.add('error');
    const errorDiv = document.createElement('div');
    errorDiv.className = 'validation-message';
    errorDiv.textContent = message;
    rowElement.appendChild(errorDiv);
  }

  function clearError(rowElement) {
    rowElement.classList.remove('error');
    const validationMessage = rowElement.querySelector('.validation-message');
    if (validationMessage) {
      validationMessage.remove();
    }
  }
}